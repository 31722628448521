import React, { lazy } from 'react';

const Homepage = lazy(() => import('./Homepage'));

const HomepageConfig = {
	settings: {
		layout: {},
	},
	routes: [
		{
			path: '/',
			element: <Homepage />,
		},
	],
};

export default HomepageConfig;
