import React, { lazy } from 'react';
import { authRoles } from '@ameroservices-platform/status-frontend/app/auth';

const JobsPage = lazy(() => import('./JobsPage'));

const JobsConfig = {
	auth: authRoles.ameroAdmin,
	routes: [
		{
			path: '/jobs',
			element: <JobsPage />,
		},
	],
};

export default JobsConfig;
