import FuseScrollbars from '@ameroservices-platform/status-frontend/fuse/core/FuseScrollbars';
import { styled, useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Logo from '@ameroservices-platform/status-frontend/app/fuse-layouts/shared-components/Logo';
import NavbarToggleButton from '@ameroservices-platform/status-frontend/app/fuse-layouts/shared-components/NavbarToggleButton';
import Navigation from '@ameroservices-platform/status-frontend/app/fuse-layouts/shared-components/Navigation';
import UserNavbarHeader from '@ameroservices-platform/status-frontend/app/fuse-layouts/shared-components/UserNavbarHeader';
import clsx from 'clsx';
import { memo, useMemo, useState } from 'react';
import { Hidden, Icon, IconButton } from '@mui/material';
import FuseUtils from '@ameroservices-platform/shared/fuse/utils';
import authRoles from '@ameroservices-platform/status-frontend/app/auth/authRoles';
import { useSelector } from 'react-redux';
import BetaDialog from '@ameroservices-platform/status-frontend/app/fuse-layouts/shared-components/BetaDialog';

const Root = styled('div')(({ theme }) => ({
	backgroundColor: theme.palette.background.default,
	color: theme.palette.text.primary,
	'& ::-webkit-scrollbar-thumb': {
		boxShadow: `inset 0 0 0 20px ${
			theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.24)' : 'rgba(255, 255, 255, 0.24)'
		}`,
	},
	'& ::-webkit-scrollbar-thumb:active': {
		boxShadow: `inset 0 0 0 20px ${
			theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.37)' : 'rgba(255, 255, 255, 0.37)'
		}`,
	},
}));

const StyledContent = styled(FuseScrollbars)(({ theme }) => ({
	overscrollBehavior: 'contain',
	overflowX: 'hidden',
	overflowY: 'auto',
	WebkitOverflowScrolling: 'touch',
	background:
		'linear-gradient(rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 30%), linear-gradient(rgba(0, 0, 0, 0.25) 0, rgba(0, 0, 0, 0) 40%)',
	backgroundRepeat: 'no-repeat',
	backgroundSize: '100% 40px, 100% 10px',
	backgroundAttachment: 'local, scroll',
}));

function NavbarStyle1Content({ toggleLargeNav, ...props }) {
	const theme = useTheme();
	const userRole = useSelector(({ auth }) => auth.user.role);
	const isAmeroAdmin = useMemo(() => FuseUtils.hasPermission(authRoles.ameroAdmin, userRole), [userRole]);
	const [betaMenuOpen, setBetaMenuOpen] = useState(false);

	return (
		<Root className={clsx('flex flex-auto flex-col overflow-hidden h-full', props.className)}>
			<AppBar
				color={process.env.NODE_ENV === 'development' ? 'secondary' : 'primary'}
				position="static"
				className="flex flex-row items-center shrink h-48 md:h-64 min-h-48 md:min-h-64 px-12 shadow-0"
			>
				<div className="flex flex-1 mx-4">
					<Logo />
				</div>

				{isAmeroAdmin && (
					<>
						<IconButton color="inherit" onClick={() => setBetaMenuOpen(true)}>
							<Icon>developer_mode</Icon>
						</IconButton>
						<BetaDialog open={betaMenuOpen} onClose={() => setBetaMenuOpen(false)} />
					</>
				)}

				<Hidden lgDown>
					<IconButton color="inherit" onClick={toggleLargeNav}>
						<Icon>aspect_ratio</Icon>
					</IconButton>
				</Hidden>

				<NavbarToggleButton className="w-40 h-40 p-0" />
			</AppBar>

			<StyledContent option={{ suppressScrollX: true, wheelPropagation: false }}>
				{/*<UserNavbarHeader />*/}

				<Navigation layout="vertical" />
			</StyledContent>
		</Root>
	);
}

export default memo(NavbarStyle1Content);
