import { createSlice } from '@reduxjs/toolkit';
import firebaseService from '@ameroservices-platform/shared/services/firebase';
import moment from 'moment-timezone';

const initialState = {
	systems: [
		{
			id: 'cloudTasksResponseTime',
			title: 'Cloud Tasks - Svartid',
			description: 'Dette er svartiden der går fra at en opgave bliver lagt i kø, til den bliver kørt',
			subSystems: [
				{
					id: 'attraction-bigquery',
					title: 'BigQuery',
					warningLevel: 10000,
					type: 'responseMS',
					description: 'Svartiden for at data bliver tilgængeligt til brug i dashboard og statistikker'
				},
				{
					id: 'attraction-elastic-search-resync-queue',
					title: 'Elastic Search Resynkronisering',
					warningLevel: 100000,
					type: 'responseMS',
					description: 'Denne service bliver brugt ved resynkroniseringer af Elastic Search data'
				},
				{
					id: 'attraction-jobs',
					title: 'Jobs',
					warningLevel: 5000,
					type: 'responseMS',
					description: 'Svartiden for generelle jobs'
				},
				{
					id: 'attraction-sync-queue',
					title: 'Ekstern Synkronisering',
					warningLevel: 10000,
					type: 'responseMS',
					description:
						'Svartiden for at data bliver tilgængeligt til brug i eksterne systemer, f.eks. MailChimp eller Elastic Search'
				},
				{
					id: 'flexpos-data-sync-queue',
					title: 'FlexPOS Synkronisering',
					warningLevel: 60000,
					type: 'responseMS',
					description: 'Svartiden for at data bliver tilgængeligt i vores system fra FlexPOS'
				}
			]
		},
		{
			id: 'cloudAppEngine',
			title: 'Cloud App Engine',
			subSystems: [
				{
					id: 'responseTime',
					title: 'Svartid',
					warningLevel: 10000,
					type: 'responseMS',
					description:
						'Tiden der går mellem at en opgave starter til den er færdig, alt i "Cloud Tasks" går igennem denne'
				}
			]
		},
		{
			id: 'cloudFunctionsResponseTime',
			title: 'Cloud Functions - Svartid',
			description: 'Dette er hvor lang tid der går mellem en funktion starter til den er færdig',
			subSystems: [
				{
					id: 'bigQueryBigQueryHandler',
					title: 'Dashboard',
					warningLevel: 2000,
					type: 'responseMS',
					description: 'Svartiden for dashboard-kald'
				},
				{
					id: 'flexposFlexTicketApi',
					title: 'FlexPOS service',
					warningLevel: 5000,
					type: 'responseMS',
					description: 'Denne service bliver kaldt, når der bliver oprettet en billet igennem FlexPOS'
				},
				{
					id: 'organisationCustomerOrderOnWrite',
					title: 'Ordremotor',
					warningLevel: 1000,
					type: 'responseMS',
					description: 'Denne håndtere ordre oprettet både via FlexPOS og online'
				},
				{
					id: 'pensoPayApi',
					title: 'PensoPay',
					warningLevel: 2000,
					type: 'responseMS',
					description: 'Denne service bliver kaldt,når der bliver betalt online'
				}
			]
		}
	],
	status: null,
	jobs: null,
	finishedJobs: null,
	organisations: {},
	pinnedJob: '0Cy7yXn9jvuY8p6NORIg'
};

const statusSlice = createSlice({
	name: 'status',
	initialState,
	reducers: {
		setPinnedJob(state, action) {
			state.pinnedJob = action.payload;
		},
		setSystems(state, action) {
			state.systems = action.payload;
		},
		setFinishedJobs(state, action) {
			state.finishedJobs = action.payload;
		},
		setJobs(state, action) {
			state.jobs = action.payload;
		},
		setOrganisation(state, action) {
			state.organisations[action.payload.id] = action.payload;
		},
		setStatus(state, action) {
			const _payload = action.payload;
			Object.entries(_payload).forEach(([key, value]) => {
				if (value.subSystems) {
					Object.entries(value.subSystems).forEach(([subKey, subValue]) => {
						subValue.values.sort((a, b) => a.time - b.time);
					});
				}
			});
			state.status = _payload;
		},
		setLastUpdated(state, action) {
			state.lastUpdated = action.payload;
		}
	}
});

export const { setPinnedJob, setStatus, setSystems, setLastUpdated, setJobs, setOrganisation, setFinishedJobs } =
	statusSlice.actions;

export const notFinishedJobsListener = () => dispatch => {
	const db = firebaseService.getRootDB();
	return db
		.collectionGroup('jobs')
		.where('deleted', '==', false)
		.where('finished', '==', false)
		.onSnapshot(q => {
			dispatch(setJobs(q.docs.map(doc => ({ ...doc.data(), id: doc.id }))));
			dispatch(setLastUpdated(moment()));
		});
};
export const finishedjobsListener = lastProcessed => dispatch => {
	const db = firebaseService.getRootDB();
	return db
		.collectionGroup('jobs')
		.where('deleted', '==', false)
		.where('lastProcessed', '>=', lastProcessed)
		.onSnapshot(q => {
			dispatch(setFinishedJobs(q.docs.map(doc => ({ ...doc.data(), id: doc.id }))));
		});
};

export const organisationListener = organisationUid => dispatch => {
	const db = firebaseService.getRootDB();
	return db
		.collection('organisations')
		.doc(organisationUid)
		.onSnapshot(doc => {
			dispatch(setOrganisation({ ...doc.data(), id: doc.id }));
		});
};

export const requestStatus = () => async dispatch => {
	const result = await firebaseService
		.callFunctionByName('statusGetStatus', undefined, true)
		.catch(() => ({ data: null }));
	dispatch(setStatus(result.data));
	dispatch(setLastUpdated(moment()));
};
export default statusSlice.reducer;

export const selectStatus = state => state.shared.status.status;
export const selectStatusBySubId = (state, systemId, subSystemId) =>
	state.shared.status.status?.[systemId]?.subSystems?.[subSystemId];
export const selectStatusById = (state, systemId) => state.shared.status.status?.[systemId];
export const selectSystems = state => state.shared.status.systems;
export const selectLastUpdated = state => state.shared.status.lastUpdated;
export const selectJobs = state =>
	[...(state.shared.status.jobs || []), ...(state.shared.status.finishedJobs || [])].filter(
		(item, index, self) => self.findIndex(f => f.id === item.id) === index
	);
export const selectOrganisationByUid = (state, organisationUid) => state.shared.status.organisations?.[organisationUid];
export const selectPinnedJob = state => state.shared.status.pinnedJob;
