import FuseNavigation from '@ameroservices-platform/status-frontend/fuse/core/FuseNavigation';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import clsx from 'clsx';
import { memo, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectNavigation } from '@ameroservices-platform/status-frontend/app/store/fuse/navigationSlice';
import { navbarCloseMobile } from '../../store/fuse/navbarSlice';
import firebaseService from '@ameroservices-platform/shared/services/firebase';
import FuseUtils from '@ameroservices-platform/shared/fuse/utils';
import authRoles from '@ameroservices-platform/status-frontend/app/auth/authRoles';

function Navigation(props) {
	const navigation = useSelector(selectNavigation);
	const theme = useTheme();
	const mdDown = useMediaQuery(theme.breakpoints.down('lg'));
	const dispatch = useDispatch();

	const modules = firebaseService.getOrganisationModules();
	const organisationId = firebaseService.getOrganisationId();
	const userRole = useSelector(({ auth }) => auth.user.role);
	const hasAdminPermission = useMemo(() => FuseUtils.hasPermission(authRoles.admin, userRole), [userRole]);
	const hasUserPermission = useMemo(() => FuseUtils.hasPermission(authRoles.user, userRole), [userRole]);

	function filterNavigationChildren(navRow) {
		const extra = {};
		if (navRow.children) {
			extra.children = navRow.children
				.filter((_navRow) => {
					if (!_navRow.requireModule) {
						return true;
					}
					if (typeof _navRow.requireModule === 'string') {
						return modules && modules[_navRow.requireModule];
					}
					if (typeof _navRow.requireModule === 'object') {
						return modules && _navRow.requireModule.some((requiredModule) => modules[requiredModule]);
					}
					return true;
				})
				.map((children) => {
					return filterNavigationChildren(children);
				});
		}
		if (process.env.NODE_ENV !== 'production' && navRow.url) {
			navRow.url = navRow.url.replace('redirect?page=', '');
		}
		return { ...navRow, ...extra };
	}

	const filteredNavigation = useMemo(() => {
		return navigation
			.filter((navRow) => {
				return !(navRow.requireOrganisation && !organisationId);
			})
			.filter((navRow) => {
				if (!navRow.requireModule) {
					return true;
				}
				if (typeof navRow.requireModule === 'string') {
					return modules && modules[navRow.requireModule];
				}
				if (typeof navRow.requireModule === 'object') {
					return modules && navRow.requireModule.some((requiredModule) => modules[requiredModule]);
				}

				return true;
			})
			.map((navRow) => {
				return filterNavigationChildren(navRow);
			});
	}, [navigation, organisationId, modules]);

	function handleItemClick(item) {
		if (mdDown) {
			dispatch(navbarCloseMobile());
		}
	}

	return (
		<FuseNavigation
			className={clsx('navigation', props.className)}
			navigation={filteredNavigation}
			layout={props.layout}
			dense={props.dense}
			active={props.active}
			onItemClick={handleItemClick}
		/>
	);
}

Navigation.defaultProps = {
	layout: 'vertical',
};

export default memo(Navigation);
