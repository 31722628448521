import i18next from 'i18next';
import ar from './navigation-i18n/ar';
import en from './navigation-i18n/en';
import tr from './navigation-i18n/tr';
import authRoles from '../auth/authRoles';

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('tr', 'navigation', tr);
i18next.addResourceBundle('ar', 'navigation', ar);

const navigationConfig = [
	{
		id: 'ameroAdmin',
		title: 'Amero Admin',
		type: 'group',
		icon: 'apps',
		auth: authRoles.ameroAdmin,
		children: [
			{
				id: 'organisations',
				title: 'Organisationer',
				type: 'item',
				icon: 'business',
				url: '/redirect?page=apps/organisations',
				exact: true,
			},
			{
				id: 'invoice',
				title: 'Fakturering',
				type: 'item',
				icon: 'account_balance',
				url: '/redirect?page=apps/invoice',
				exact: true,
			},
		],
	},
	{
		id: 'user',
		title: '',
		type: 'group',
		icon: 'apps',
		requireOrganisation: true,
		auth: authRoles.user,
		children: [
			{
				id: 'dashboard',
				title: 'Dashboard',
				type: 'item',
				icon: 'dashboard',
				url: '/redirect?page=apps/dashboard',
				exact: true,
			},
			{
				id: 'tickets',
				title: 'Billetter',
				type: 'item',
				icon: 'local_activity',
				url: '/redirect?page=apps/tickets',
				exact: true,
			},
			{
				id: 'subscriptions',
				title: 'Medlemskaber',
				type: 'item',
				icon: 'update',
				url: '/redirect?page=apps/subscriptions',
				exact: true,
				requireModule: 'subscriptions',
			},
			{
				id: 'customers',
				title: 'Kunder',
				type: 'item',
				icon: 'people',
				url: '/redirect?page=apps/customers',
				exact: true,
			},
			{
				id: 'carts',
				title: 'Kurve',
				type: 'item',
				icon: 'shopping_basket',
				url: '/redirect?page=apps/carts',
				auth: authRoles.ameroAdmin,
				exact: true,
			},
			{
				id: 'orders',
				title: 'Ordrer',
				type: 'item',
				icon: 'shopping_cart',
				url: '/redirect?page=apps/orders',
				exact: true,
			},
			{
				id: 'refunds',
				title: 'Refunderinger',
				type: 'item',
				icon: 'keyboard_return',
				url: '/redirect?page=apps/refunds',
				exact: true,
			},
			{
				id: 'manualEvents',
				title: 'Manuelle Begivenheder',
				type: 'collapse',
				icon: 'event',
				exact: true,
				children: [],
				requireModule: 'manualEvents',
			},
			{
				id: 'automaticEvents',
				title: 'Automatiske Begivenheder',
				type: 'collapse',
				icon: 'event',
				exact: true,
				children: [],
				requireModule: 'automaticEvents',
			},
		],
	},

	{
		id: 'admin-diverse',
		title: '━━━━━━━━━━━━━━━━━━━━━━',
		type: 'group',
		icon: 'apps',
		requireOrganisation: true,
		auth: authRoles.admin,
		children: [
			{
				id: 'reports',
				title: 'Rapporter',
				type: 'item',
				icon: 'library_books',
				url: '/redirect?page=apps/reports',
				exact: true,
			},
			{
				id: 'statistics',
				title: 'Statistik',
				type: 'item',
				icon: 'equalizer',
				url: '/apps/statistics',
				exact: true,
				requireModule: 'statistics',
			},
			{
				id: 'budgets',
				title: 'Budgetter',
				type: 'item',
				icon: 'forward',
				url: '/apps/budgets',
				exact: true,
				requireModule: 'statistics',
			},
			{
				id: 'importJobs',
				title: 'Importér',
				type: 'item',
				icon: 'import_export',
				url: '/redirect?page=apps/import-jobs',
				exact: true,
				auth: authRoles.ameroAdmin,
			},
		],
	},
	{
		id: 'admin',
		title: '━━━━━━━━━━━━━━━━━━━━━━',
		type: 'group',
		icon: 'apps',
		requireOrganisation: true,
		auth: authRoles.admin,
		children: [
			{
				id: 'productConfiguration',
				title: 'Produkt Opsætning',
				type: 'collapse',
				icon: 'shop_two',
				children: [
					{
						id: 'taxGroups',
						title: 'Momskoder',
						type: 'item',
						icon: 'account_balance',
						url: '/redirect?page=apps/settings/vat',
						exact: true,
					},
					{
						id: 'ticketTypes',
						title: 'Produktgrupper',
						type: 'item',
						icon: 'local_activity',
						url: '/redirect?page=apps/settings/product-groups',
						exact: true,
					},
					{
						id: 'products',
						title: 'Produkter',
						type: 'collapse',
						icon: 'shop_two',
						exact: true,
					},
					{
						id: 'followProducts',
						title: 'Følgeprodukter',
						type: 'item',
						icon: 'shopping_bag',
						url: '/redirect?page=apps/follow-products',
						exact: true,
					},
				],
			},
			{
				id: 'memberConfiguration',
				title: 'Medlems Opsætning',
				type: 'collapse',
				icon: 'group',
				requireModule: 'subscriptions',
				children: [
					{
						id: 'subscriptionTypes',
						title: 'Medlemskabstyper',
						type: 'item',
						icon: '360',
						url: '/redirect?page=apps/settings/subscription-types',
						exact: true,
					},
				],
			},
			{
				id: 'eventConfiguration',
				title: 'Begivenhedsopsætning',
				type: 'collapse',
				icon: 'event',
				requireModule: ['automaticEvents', 'manualEvents'],
				children: [
					{
						id: 'eventGroups',
						title: 'Grupper',
						type: 'item',
						icon: 'event_note',
						url: '/redirect?page=apps/settings/event-groups',
						exact: true,
					},
					{
						id: 'eventSchedulers',
						title: 'Planlægning',
						type: 'collapse',
						icon: 'event_note',
						requireModule: 'automaticEvents',
						exact: true,
					},
					{
						id: 'eventAttributeGroups',
						title: 'Attribut Grupper',
						type: 'collapse',
						icon: 'edit_attributes',
						exact: true,
						children: [],
					},
				],
			},
			{
				id: 'content',
				title: 'Frontend Opsætning',
				type: 'collapse',
				icon: 'wallpaper',
				children: [
					{
						id: 'pages',
						title: 'Sider',
						type: 'item',
						icon: 'web',
						url: '/redirect?page=apps/frontend-settings/pages',
						exact: true,
						auth: authRoles.ameroAdmin,
					},
					{
						id: 'pageContents',
						title: 'Side indhold',
						type: 'collapse',
						icon: 'web',
					},
					{
						id: 'theme',
						title: 'Tema',
						type: 'item',
						icon: 'color_lens',
						url: '/redirect?page=apps/frontend-settings/theme',
						exact: true,
					},
					{
						id: 'faqs',
						title: 'FAQ',
						type: 'item',
						icon: 'question_answer',
						url: '/redirect?page=apps/frontend-settings/faqs',
						exact: true,
					},
					{
						id: 'navigations',
						title: 'Navigationer',
						type: 'collapse',
						icon: 'wallpaper',
						children: [],
					},
					{
						id: 'footer',
						title: 'Footer',
						type: 'item',
						icon: 'settings',
						url: '/redirect?page=apps/frontend-settings/footer',
						exact: true,
					},
					{
						id: 'header',
						title: 'Header',
						type: 'item',
						icon: 'settings',
						url: '/redirect?page=apps/frontend-settings/header',
						exact: true,
					},
				],
			},
			{
				id: 'flexposSettings',
				title: 'FlexPOS Opsætning',
				type: 'collapse',
				icon: 'computer',
				children: [
					{
						id: 'flexposSettings-ticketSale',
						title: 'Billetsalg',
						type: 'item',
						icon: 'computer',
						url: '/redirect?page=apps/flexpos-settings/ticket-sale',
						exact: true,
					},
					{
						id: 'flexposSettings-members',
						title: 'Medlemsmodul',
						type: 'item',
						icon: 'group',
						url: '/redirect?page=apps/flexpos-settings/members',
						exact: true,
						requireModule: 'subscriptions',
					},
				],
			},
			{
				id: 'systemConfiguration',
				title: 'System Opsætning',
				type: 'collapse',
				icon: 'settings',
				children: [
					{
						id: 'incrementalNumbers',
						title: 'Fortløbende numre',
						type: 'item',
						icon: 'plus_one',
						url: '/redirect?page=apps/settings/incremental-numbers',
						exact: true,
					},
					{
						id: 'mails',
						title: 'Mail Skabeloner',
						type: 'item',
						icon: 'mail',
						url: '/redirect?page=apps/settings/mail',
						auth: authRoles.ameroAdmin,
						exact: true,
					},
					{
						id: 'locations',
						title: 'Lokationer',
						type: 'item',
						icon: 'location_city',
						url: '/redirect?page=apps/settings/locations',
						auth: authRoles.admin,
						exact: true,
					},
					{
						id: 'lists',
						title: 'Lister',
						type: 'item',
						icon: 'list',
						url: '/redirect?page=apps/settings/lists',
						auth: authRoles.ameroAdmin,
						exact: true,
					},
					{
						id: 'countries',
						title: 'Lande',
						type: 'item',
						icon: 'public',
						url: '/redirect?page=apps/settings/countries',
						auth: authRoles.ameroAdmin,
						exact: true,
					},
					{
						id: 'financeDepartments',
						title: 'Finansafdelinger',
						type: 'item',
						icon: 'credit_card',
						url: '/apps/finance-departments',
						exact: true,
						requireModule: 'statistics',
						auth: authRoles.ameroAdmin,
					},
				],
			},
		],
	},
];

export default navigationConfig;
