import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import store from '@ameroservices-platform/status-frontend/app/store';
import { logoutUser } from '@ameroservices-platform/status-frontend/app/auth/store/userSlice';
import { useDispatch, useSelector } from 'react-redux';

function Logout() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const authUser = useSelector(({ auth }) => auth.user);

	useEffect(() => {
		dispatch(logoutUser());
	}, [dispatch]);

	useEffect(() => {
		if (!authUser?.data?.email) {
			navigate('/');
		}
	}, [authUser, navigate]);
	return 'Logging out..';
}

export default Logout;
