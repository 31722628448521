/* eslint import/no-extraneous-dependencies: off */
import { createSlice } from '@reduxjs/toolkit';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import history from '@ameroservices-platform/shared-fuse-history';
import _ from '@ameroservices-platform/shared-fuse-lodash';
import { setInitialSettings } from '@ameroservices-platform/status-frontend/app/store/fuse/settingsSlice';
import { showMessage } from '@ameroservices-platform/status-frontend/app/store/fuse/messageSlice';
import firebaseService from '@ameroservices-platform/shared/services/firebase';
import settingsConfig from '@ameroservices-platform/status-frontend/app/fuse-configs/settingsConfig';

export const setUserDataFirebase = (user, authUser) => async (dispatch) => {
	if (user && user.data) {
		// Set user data but do not update
		return dispatch(setUserData(user));
	}

	// Create missing user settings
	return dispatch(createUserSettingsFirebase(user, authUser));
};

export const createUserSettingsFirebase = (dbUser, authUser) => async (dispatch, getState) => {
	const guestUser = getState().auth.user;
	const { currentUser } = firebase.auth();

	/**
	 * Merge with current Settings
	 */
	const user = _.merge({}, dbUser, {
		uid: authUser.uid,
		from: 'firebase',
		data: {
			displayName: authUser.displayName,
			email: authUser.email,
			shortcuts: guestUser && guestUser.data && guestUser.data.shortcuts ? guestUser.data.shortcuts : [],
		},
	});
	await currentUser.updateProfile(user.data);

	dispatch(updateUserData(user));

	return dispatch(setUserData(user));
};

export const setUserData = (user) => async (dispatch, getState) => {
	/*
        You can redirect the logged-in user to a specific route depending on his role
         */

	if (user?.claims?.loginRedirectUrl) {
		settingsConfig.loginRedirectUrl = decodeURIComponent(user.claims.loginRedirectUrl);
	}

	/*
    Set User Settings
     */
	// dispatch(setSettings({loginRedirectUrl: user.claims.loginRedirectUrl}));
	console.log(user);

	dispatch(setUser(user));
};

export const updateUserSettings = (settings) => async (dispatch, getState) => {
	const oldUser = getState().auth.user;
	const user = _.merge({}, oldUser, { data: { settings } });

	dispatch(updateUserData(user));

	return dispatch(setUserData(user));
};

export const updateUserShortcuts = (shortcuts) => async (dispatch, getState) => {
	const { user } = getState().auth;
	const newUser = {
		...user,
		data: {
			...user.data,
			shortcuts,
		},
	};

	dispatch(updateUserData(newUser));

	return dispatch(setUserData(newUser));
};

export const logoutUser = () => async (dispatch, getState) => {
	const { user } = getState().auth;

	if (!user.role || user.role.length === 0) {
		// is guest
		return null;
	}

	history.push({
		pathname: '/',
	});

	firebaseService.signOut();

	dispatch(setInitialSettings());

	dispatch(userLoggedOut());

	return true;
};

export const updateUserData = (user) => async (dispatch, getState) => {
	if (!user.role || user.role.length === 0) {
		// is guest
		return;
	}
	firebaseService
		.updateUserData(user)
		.then(() => {
			dispatch(showMessage({ message: 'User data saved to firebase' }));
		})
		.catch((error) => {
			dispatch(showMessage({ message: error.message }));
		});
};

const initialState = {
	role: [], // guest
	data: {
		displayName: '',
		photoURL: '',
		email: '',
		shortcuts: [],
	},
};

const userSlice = createSlice({
	name: 'auth/user',
	initialState,
	reducers: {
		setUser: (state, action) => action.payload,
		userLoggedOut: (state, action) => initialState,
	},
	extraReducers: {},
});

export const { setUser, userLoggedOut } = userSlice.actions;

export default userSlice.reducer;
