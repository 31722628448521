import { authRoles } from '@ameroservices-platform/status-frontend/app/auth';
import Logout from '@ameroservices-platform/status-frontend/app/main/logout/Logout';

const LogoutConfig = {
	auth: authRoles.user,
	routes: [
		{
			path: '/logout',
			element: <Logout />,
		},
	],
};

export default LogoutConfig;
