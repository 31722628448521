import FuseUtils from '@ameroservices-platform/shared/fuse/utils';
import HomepageConfig from '@ameroservices-platform/status-frontend/app/main/home/HomepageConfig';
import FuseLoading from '@ameroservices-platform/status-frontend/fuse/core/FuseLoading';
import { Navigate } from 'react-router-dom';
import authRoles from '@ameroservices-platform/status-frontend/app/auth/authRoles';
import LoginConfig from '@ameroservices-platform/status-frontend/app/main/login/LoginConfig';
import JobsConfig from '@ameroservices-platform/status-frontend/app/main/jobs/JobsConfig';
import LogoutConfig from '@ameroservices-platform/status-frontend/app/main/logout/LogoutConfig';

const routeConfigs = [HomepageConfig, LoginConfig, LogoutConfig, JobsConfig];

const routes = [
	// if you want to make whole app auth protected by default change defaultAuth for example:
	// ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['admin','staff','user']),
	// The individual route configs which has auth option won't be overridden.
	...FuseUtils.generateRoutesFromConfigs(routeConfigs),
	{
		path: '*',
		element: <Navigate to="/" />,
	},
];

export default routes;
